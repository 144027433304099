import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/ceibs/ceibs.png"
import ceibs1 from "../../../assets/images/portfolio_items/ceibs/ceibs1.png"
import ceibs2 from "../../../assets/images/portfolio_items/ceibs/ceibs2.png"
import ceibs3 from "../../../assets/images/portfolio_items/ceibs/ceibs3.png"
import ceibs4 from "../../../assets/images/portfolio_items/ceibs/ceibs4.png"
import ceibs5 from "../../../assets/images/portfolio_items/ceibs/ceibs5.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | CEIBS - Drupal Website Development Project'} 
    description="A sunning Drupal Website Development project for the Chinese European International Business School: give a look at ICON's portfolio to discover more!"
    canonical={'https://icon-worldwide.com/works/ceibs'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="Chinese European International Business School, ICON Worldwide portfolio, drupal website development" title="ICON Worldwide portfolio, Chinese European International Business School, drupal website development"/>
            <div className="title"><h1>Chinese European International Business School<span></span></h1>
            <h2>Drupal Website Development</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>The Chinese European International Business School is the top business school in Asia, and Zurich is home to its’ European headquarters.
        For the Swiss launch, we created the university website in Drupal.</p>
        <div className="portfolio-images" id="ceibs">
            <img src={ceibs1} alt="Chinese European International Business School, ICON Worldwide portfolio, drupal website development" title="ICON Worldwide portfolio, Chinese European International Business School, drupal website development"/>
            <img src={ceibs3} alt="Chinese European International Business School, ICON Worldwide portfolio, drupal website development" title="ICON Worldwide portfolio, Chinese European International Business School, drupal website development"/>
            <img src={ceibs2} alt="Chinese European International Business School, ICON Worldwide portfolio, drupal website development" title="ICON Worldwide portfolio, Chinese European International Business School, drupal website development"/>
            <img className="mobile-img" src={ceibs5} alt="Chinese European International Business School, ICON Worldwide portfolio, drupal website development" title="ICON Worldwide portfolio, Chinese European International Business School, drupal website development"/>
        </div>
        <img className="full-img desktop-img" src={ceibs4} alt="Chinese European International Business School, ICON Worldwide portfolio, drupal website development" title="ICON Worldwide portfolio, Chinese European International Business School, drupal website development"/>
        <WorkFooter previous="actelion" next="iron-deficiency"/>
        </div>
    </div>
</div>